import request from '@/utils/axios'

/** 获取已发布项目列表 */
export function getReleaseList(params) {
  return request({
    url: '/project/release',
    method: 'get',
    params
  })
}

/** 获取已发布项目详情 */
export function getDetail(params) {
  return request({
    url: '/project/release/detail',
    method: 'get',
    params
  })
}

/** 屏蔽项目 */
export function shield(params) {
  return request({
    url: '/project/release',
    method: 'put',
    params
  })
}

/** 设置项目推荐 */
export function recommend(data) {
  return request({
    url: '/project/release/recommend',
    method: 'put',
    data
  })
}

/** 设置项目置顶 */
export function topping(data) {
  return request({
    url: '/project/release/top',
    method: 'put',
    data
  })
}

/** 取消项目置顶 */
export function cancelTop(id) {
  return request({
    url: `/project/release/cancel/top/${id}`,
    method: 'get'
  })
}

/** 已归档的项目列表 */
export function getAuditList(params) {
  return request({
    url: '/project/audit',
    method: 'get',
    params
  })
}

/** 已归档的项目详情 */
export function getAuditDetail(params) {
  return request({
    url: '/project/audit/detail',
    method: 'get',
    params
  })
}

/** 待审核的项目列表 */
export function getExamineList(params) {
  return request({
    url: '/project/archive',
    method: 'get',
    params
  })
}

/** 待审核的项目详情 */
export function getExamineDetail(params) {
  return request({
    url: '/project/archive/detail',
    method: 'get',
    params
  })
}

/** 待审核的项目审核 */
export function examine(params) {
  return request({
    url: '/project/archive',
    method: 'put',
    params
  })
}

/** 取消推荐 */
export function cancelRecommend(id) {
  return request({
    url: `/project/release/cancel/recommend/${id}`,
    method: 'put'
  })
}
