<template>
  <el-dialog
    title="项目详情"
    :visible.sync="detailShow"
    width="80%"
  >
    <template v-if="detailShow">
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item label="发布人">{{ detailInfo.customerName }}</el-descriptions-item>
        <el-descriptions-item label="发布人昵称">{{ detailInfo.nickname }}</el-descriptions-item>
        <el-descriptions-item span="2" label="头像">
          <img style="width:100px;height:100px;" :src="detailInfo.headUrl" alt="">
        </el-descriptions-item>
        <el-descriptions-item label="项目名称">{{ detailInfo.name }}</el-descriptions-item>
        <!-- <el-descriptions-item label="项目类型">{{ releaseType[detailInfo.releaseType] }}</el-descriptions-item> -->
        <el-descriptions-item label="项目编号">{{ detailInfo.code }}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{ detailInfo.createTime }}</el-descriptions-item>
        <el-descriptions-item label="截止时间">{{ detailInfo.validityTime }}</el-descriptions-item>
        <el-descriptions-item label="项目所在地">{{ detailInfo.address }}</el-descriptions-item>
        <el-descriptions-item label="发布人所在地">{{ detailInfo.customerAddr }}</el-descriptions-item>
        <el-descriptions-item label="微信号">{{ detailInfo.wechat }}</el-descriptions-item>
        <el-descriptions-item label="解锁金币数">{{ detailInfo.coin }}</el-descriptions-item>
        <el-descriptions-item label="项目详情">{{ detailInfo.content }}</el-descriptions-item>
      </el-descriptions>
    </template>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/product'
export default {
  mixins: [form],
  data() {
    return {
      api
    }
  }
}
</script>

<style>

</style>
