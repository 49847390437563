<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.name" placeholder="项目名称" clearable />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="createTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="项目发布开始日期"
            end-placeholder="项目发布结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="change"
          />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.type" placeholder="请选择项目分类" clearable>
            <el-option v-for="(value, key) in types" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.address" placeholder="项目所在地" clearable />
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.customerAddr" placeholder="发布人所在地" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="choceSearch">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="name"
        label="项目名称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createTime"
        label="发布时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="type"
        label="分类"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ types[row.type] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="项目所在地"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="customerAddr"
        label="发布人所在地"
        align="center"
        show-overflow-tooltip
      />
      <!-- <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ status[row.status] }}
        </template>
      </el-table-column> -->
      <el-table-column
        prop="content"
        label="项目摘要"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="browse"
        label="被浏览数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        v-if="method.includes('/release')"
        prop="topTime"
        label="置顶截止日"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        v-if="method.includes('/release')"
        prop="recommendTime"
        label="推荐截止日"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
        width="220px"
      >
        <template slot-scope="{ row }">
          <el-row v-if="method.includes('/release')">
            <el-button v-if="row.top" type="primary" size="small" @click="cancelTop(row.id)">取消置顶</el-button>
            <el-button v-else type="primary" size="small" @click="topping(row.id)">置顶</el-button>
            <el-button v-if="row.recommend" type="danger" size="small" @click="cancelRecommend(row.id)">取消推荐</el-button>
            <el-button v-else type="danger" size="small" @click="recommend(row.id)">推荐</el-button>
          </el-row>
          <el-row style="margin-top:10px;display: flex;align-items: center;justify-content: center;">
            <el-button type="primary" size="small" @click="detail(row.id)">详情</el-button>
            <div v-if="method.includes('/release')" style="margin-left: 10px">
              <el-popconfirm
                title="确定要屏蔽吗？"
                @confirm="shield(row.id)"
              >
                <el-button slot="reference" type="danger" size="small">屏蔽</el-button>
              </el-popconfirm>
            </div>
            <div v-if="method.includes('/examine')" style="margin-left: 10px">
              <el-popconfirm
                title="确定要通过审核吗？"
                @confirm="examineAdopt(row.id)"
              >
                <el-button slot="reference" type="danger" size="small">审核通过</el-button>
              </el-popconfirm>
            </div>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="currentChange"
      />
    </div>
    <!-- 设置推荐 -->
    <el-dialog
      title="设置截止日期"
      :visible.sync="recommendShow"
      width="600px"
    >
      <el-date-picker
        v-model="recommendTime"
        type="date"
        placeholder="请选择截止日期"
        value-format="yyyy-MM-dd"
        :picker-options="expireTimeOption"
      />
      <el-button type="primary" style="margin: 0 auto;display: block;margin-top: 40px;" @click="onSubmit">确定</el-button>
    </el-dialog>
    <!-- 设置置顶 -->
    <el-dialog
      title="设置截止日期"
      :visible.sync="toppingShow"
      width="600px"
    >
      <el-date-picker
        v-model="toppingTime"
        type="date"
        placeholder="请选择截止日期"
        value-format="yyyy-MM-dd"
        :picker-options="expireTimeOption"
      />
      <el-button type="primary" style="margin: 0 auto;display: block;margin-top: 40px;" @click="onSubmitTopping">确定</el-button>
    </el-dialog>
    <!-- 详情 -->
    <Form ref="form" />
  </div>
</template>

<script>
import * as api from '@/api/product'
import Form from './form'
import { recommend, topping, shield, cancelTop, cancelRecommend, getReleaseList } from '@/api/product'
export default {
  components: { Form },
  data() {
    return {
      api,
      list: [],
      count: 0,
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50, 100],
      loading: false,
      query: {},
      queryTime: {},
      types: {
        1: '找项目',
        2: '找资金',
        3: '找合作'
      },
      // 设置推荐
      recommendShow: false,
      recommendId: '',
      recommendTime: '',
      // 设置当前日期之前的日期不可选(日期选择器)
      expireTimeOption: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      toppingShow: false,
      toppingId: '',
      toppingTime: '',
      createTime: []
    }
  },
  computed: {
    method() {
      return this.$route.path
    }
  },
  watch: {
    method() {
      if (this.method.includes('/release')) {
        this.refresh()
        return this.getReleaseList()
      }
      if (this.method.includes('/examine')) {
        this.refresh()
        return this.getExamineList()
      }
      if (this.method.includes('/file')) {
        this.refresh()
        return this.getAuditList()
      }
    }
  },
  created() {
    if (this.method.includes('/release')) {
      return this.getReleaseList()
    } else
    if (this.method.includes('/examine')) {
      return this.getExamineList()
    } else
    if (this.method.includes('/file')) {
      return this.getAuditList()
    }
  },
  methods: {
    detail(id) {
      this.$refs.form.detail(id)
    },
    // 重置
    refresh() {
      this.createTime = []
      this.query = {}
      this.page = 1
      this.queryTime = {}
      if (this.method.includes('/release')) {
        return this.getReleaseList()
      }
      if (this.method.includes('/examine')) {
        return this.getExamineList()
      }
      if (this.method.includes('/file')) {
        return this.getAuditList()
      }
    },
    // 设置推荐
    recommend(id) {
      this.recommendId = id
      this.recommendShow = true
    },
    onSubmit() {
      recommend({ id: this.recommendId, time: this.recommendTime }).then(() => {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.recommendShow = false
        this.getReleaseList()
      })
    },
    // 设置置顶
    topping(id) {
      this.toppingId = id
      this.toppingShow = true
    },
    // 取消置顶
    cancelTop(id) {
      cancelTop(id).then(() => {
        this.getReleaseList()
        this.$message.success('设置成功')
      })
    },
    // 取消推荐
    cancelRecommend(id) {
      cancelRecommend(id).then(() => {
        this.$message.success('取消成功')
        this.getReleaseList()
      })
    },
    onSubmitTopping() {
      topping({ id: this.toppingId, time: this.toppingTime }).then(() => {
        this.toppingTime = ''
        this.toppingShow = false
        this.getReleaseList()
        this.$message({
          message: '设置成功',
          type: 'success'
        })
      })
    },
    // 屏蔽
    shield(id) {
      shield({ id }).then(() => {
        this.$message({
          message: '屏蔽成功',
          type: 'success'
        })
        this.toppingShow = false
        this.getReleaseList()
      })
    },
    // 已发布列表
    getReleaseList() {
      this.loading = true
      getReleaseList({ ...this.query, page: this.page, pageSize: this.pageSize })
        .then(res => {
          const { list = [], count = 0 } = res.data
          this.list = list
          this.count = count
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 待审核列表
    getExamineList() {
      this.loading = true
      this.api.getExamineList({ ...this.query, page: this.page, pageSize: this.pageSize })
        .then(res => {
          const { list = [], count = 0 } = res.data
          this.list = list
          this.count = count
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 已归档列表
    getAuditList() {
      this.loading = true
      this.api.getAuditList({ ...this.query, page: this.page, pageSize: this.pageSize })
        .then(res => {
          const { list = [], count = 0 } = res.data
          this.list = list
          this.count = count
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 审核通过
    examineAdopt(id) {
      this.api.examine({ id }).then(() => {
        this.$message({
          message: '审核通过',
          type: 'success'
        })
        this.getExamineList()
      })
    },
    choceSearch() {
      if (this.method.includes('/release')) {
        return this.getReleaseList()
      }
      if (this.method.includes('/examine')) {
        return this.getExamineList()
      }
      if (this.method.includes('/file')) {
        return this.getAuditList()
      }
    },
    currentChange(val) {
      this.page = val
      if (this.method.includes('/release')) {
        return this.getReleaseList()
      }
      if (this.method.includes('/examine')) {
        return this.getExamineList()
      }
      if (this.method.includes('/file')) {
        return this.getAuditList()
      }
    },
    change(val) {
      this.query.startTime = val[0]
      this.query.endTime = val[1]
    }
  }
}
</script>
